/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://xgardv72ofcojnki2ypaxlejty.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-udjag4rqbfcbxpmyvsfe6i6hce",
    "aws_cognito_identity_pool_id": "eu-west-1:bf7b1930-f556-4899-bd26-fd11f7430985",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_FAjJ8clQf",
    "aws_user_pools_web_client_id": "7ue0afj3ejtmesmi9d8hkptjaq",
    "oauth": {
        "domain": "mppsso-demo.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://launch.mypropass.co.uk/sso-callback/,http://localhost:8080/sso-callback/,https://dev.mypropass.co.uk/sso-callback/,https://staging.mypropass.co.uk/sso-callback/,https://demo.mypropass.co.uk/sso-callback/",
        "redirectSignOut": "https://launch.mypropass.co.uk/sso-callback/,http://localhost:8080/sso-callback/,https://dev.mypropass.co.uk/sso-callback/,https://staging.mypropass.co.uk/sso-callback/,https://demo.mypropass.co.uk/sso-callback/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mppweb5869f09c00d1420e9cc4168a90cf8c79160753-demo",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
